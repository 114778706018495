
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")

import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free"

FontAwesome.config.mutateApproach = 'sync'


